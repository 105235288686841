import FacebookLikeBoxComponent from '@wix/thunderbolt-elements/src/components/FacebookLikeBox/viewer/FacebookLikeBox';
import FacebookLikeBoxController from '@wix/thunderbolt-elements/src/components/FacebookLikeBox/viewer/FacebookLikeBox.controller';


const FacebookLikeBox = {
  component: FacebookLikeBoxComponent,
  controller: FacebookLikeBoxController
};


export const components = {
  ['FacebookLikeBox']: FacebookLikeBox
};

